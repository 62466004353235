<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <div class="col-12 md:col-12">
        <app-basic-input
          label="Name"
          [placeholder]="'Enter Name'"
          [control]="formGroup?.controls?.name"
          [viewMode]="fieldViewMode"
        ></app-basic-input>
      </div>
      <div class="col-12 md:col-12">
        <app-suggestion-input
          [label]="'Frequency'"
          [placeholder]="'Ex: 7 days'"
          [control]="formGroup?.controls?.frequency"
          [viewMode]="fieldViewMode"
          [items]="[
            { label: 'Yearly', value: 365 },
            { label: 'Quarterly', value: 90 },
            { label: 'Monthly', value: 30 },
            { label: 'Weekly', value: 7 },
            { label: 'Daily', value: 1 },
          ]"
          [type]="'number'"
          [min]="0"
          [maxFractionDigits]="0"
        >
        </app-suggestion-input>
      </div>
      <div class="col-12">
        <app-drop-down-input
          [label]="'Compliance Report Type'"
          [placeholder]="'Select Type'"
          [name]="'dropdown'"
          [optionLabel]="'label'"
          [optionValue]="'value'"
          [items]="complianceReportTypesList"
          [optionValue]="'value'"
          [control]="formGroup?.controls?.complianceReportType"
          [viewMode]="fieldViewMode == 'create' ? 'create' : 'view'"
          [badgeView]="true"
        ></app-drop-down-input>
      </div>
      @if (currentCompliaceType) {
        <div *ngxPermissionsOnly="mapperService?.mappedFields['scopeItems']?.permissions" class="col-12 md:col-12">
          <app-target-code-selector
            [label]="currentCompliaceType | humanize"
            [placeholder]="'Search ' + (currentCompliaceType | humanize)"
            [control]="formGroup?.controls?.scopeItems"
            [viewMode]="fieldViewMode"
            [multi]="true"
            [optionLabel]="'name'"
            [optionValue]="'code'"
            [targetTypes]="[currentCompliaceType]"
          >
          </app-target-code-selector>
        </div>
      }
      <div class="col-12 md:col-12">
        <app-text-editor
          [name]="'description'"
          label="Description"
          [placeholder]="'Enter Text...'"
          [control]="formGroup?.controls?.description"
          [viewMode]="fieldViewMode"
          [advanced]="true"
        ></app-text-editor>
      </div>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
